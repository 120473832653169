<template>
	<div>
		<top-nav @back="back">被保人信息</top-nav>
		<formGroupItem ref="form" v-if="formOptions.length" v-model="form" :form-option="formOptions" :rules="rules" />
		<div class="btns">
			<div class="btn btn_cancel" @click="cancel">取消</div>
			<div class="btn btn_sure" @click="sure">确定</div>
		</div>
	</div>
</template>

<script>
import formGroupItem from '@/components/formGroupItem.vue';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';
import { http_insTranslate } from '@/request/common';
export default {
	components: {
		formGroupItem,
	},
	data() {
		return {
			form: {
				name: '',
				englishName: '',
				certificateType: this.$route.params.cardTypeList[0].name,
				number: '',
				birthday: '',
				sex: '',
				phone: '',
				price: '',
				profession: '',
			},
			formOptions: [
				{ label: '姓名', prop: 'name' },
				{
					label: '英文名',
					prop: 'englishName',
					hidden: !this.$route.params.hasEnglishName,
				},
				{
					label: '证件类型',
					prop: 'certificateType',
					type: 'picker',
					options: this.$route.params.cardTypeList,
				},
				{ label: '证件号码', prop: 'number' },
				{
					label: '出生日期',
					prop: 'birthday',
					type: 'datetimePicker',
					minDate: new Date('1900/01/01'),
				},
				{
					label: '性别',
					prop: 'sex',
					type: 'picker',
					options: [
						{ label: '男', name: '男' },
						{ label: '女', name: '女' },
					],
				},
				{
					label: '职业',
					prop: 'profession',
					type: 'cascader',
					cascaderShow: 'last',
					options: this.$route.params.professionList || [],
					hidden: !this.$route.params.hasProfession,
				},
				{
					label: '手机号码',
					prop: 'phone',
				},
			],
			// 被保人信息校验规则
			rules: {
				name: [
					{ required: true, trigger: 'onBlur' },
					{
						pattern: regular.name1,
						message: '只能输入中文或英文 以及·',
					},
					{
						pattern: regular.name2,
						message: '至少2个中文或4个英文字母',
					},
					{
						validator: this.nameCheck,
						trigger: 'onChange',
						message: '·不能出现在首尾',
					},
				],
				englishName: [{ required: !!this.$route.params.hasEnglishName, trigger: 'onBlur' }],
				certificateType: [{ required: true, trigger: 'onBlur' }],
				number: [
					{ required: true, trigger: 'onBlur' },
					{
						validator: this.insuredCardNumCheck,
						trigger: 'onBlur',
						message: '证件号码有误',
					},
				],
				birthday: [
					{ required: true, trigger: 'onChange' },
					{
						validator: this.ageEnableCheck,
						trigger: 'onChange',
						message: '年龄不符合承保范围',
					},
				],
				sex: [{ required: true, trigger: 'onChange' }],
				phone: [
					{
						validator: this.phoneCheck,
						trigger: 'onBlur',
						message: '手机号码有误',
					},
				],
				profession: [{ required: !!this.$route.params.hasProfession, trigger: 'onChange' }],
			},
		};
	},
	computed: {},
	watch: {
		'form.number'() {
			this.numberCheck();
		},
		'form.name'(val) {
			if (this.$route.params.hasEnglishName) {
				let reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g');
				const reg2 = /[a-zA-Z]{4,}|[\u4e00-\u9fa5𬜬]{2,}/;
				if (reg.test(val) && reg2.test(val) && val) {
					http_insTranslate([String(val)]).then(res => {
						this.form.englishName = res[String(val)];
					}); 
				}
			}
		},
		'$route': {
			handler: async function (route) {
				let params = route.params;
				if (params.hasOwnProperty('info')) {
					Object.assign(this.form, params.info);
					this.$nextTick(() => {
						this.$refs.form.handleData();
					});
				}
			},
			immediate: true,
		},
	},
	methods: {
		// 校验规则-被保人姓名
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},
		// 校验规则-被保险人证件号码
		insuredCardNumCheck(val) {
			// 身份证
			if (this.form.certificateType === '01') {
				return Mtils.validation.isIdCard(val);
			}
			//   护照
			if (this.form.certificateType === '02') {
				return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
			}
			//   军官证
			if (this.form.certificateType === '03') {
				return regular.army.test(val);
			}
			//港澳回乡证
			if (this.form.certificateType === '05') {
				return regular.hkCard.test(val);
			}
			//台胞证
			if (this.form.certificateType === '07') {
				return regular.twCard.test(val);
			}
			return true;
		},
		// 校验规则-被保人年龄是否符合产品投保要求
		ageEnableCheck() {
			let age = this.$base.getAgeFromStartTime(this.form.birthday,this.$store.state.insuranceV2.startTime);
			const { protectAgeMin: min, protectAgeMax: max } = this.$store.state.insuranceV2;
			if ((min !== undefined && age < min) || (max !== undefined && age > max)) {
				return false;
			}
			return true;
		},
		// 校验手机号码
		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},
		// 证件号码校验
		numberCheck() {
			// 身份证
			if (this.form.certificateType === '01') {
				// 字母大小写
				if (this.form.number) {
					this.form.number = this.form.number.toLocaleUpperCase();
				}
				// 计算出生日期、性别
				if (Mtils.validation.isIdCard(this.form.number)) {
					const { birthday, sex } = this.$base.getInfoFromIdNumber(this.form.number);
					this.form.birthday = birthday
					this.form.sex = sex;
				}
			}
		},
		cancel() {
			this.back();
		},
		back() {
			this.$router.push({ name: this.$store.state.createOrderRouteName });
		},
		async sure() {
			await this.$refs.form.handleData();
			this.$router.push({
				name: this.$store.state.createOrderRouteName,
				params: {
					info: this.form,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.btns {
	margin-top: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	.btn {
		width: 3rem;
		height: 0.9rem;
		font-size: 0.3rem;
		line-height: 0.9rem;
		text-align: center;
		border-radius: 0.08rem;
	}
	.btn_cancel {
		border: 1px solid $color_main;
		color: $color_main;
		background-color: #fff;
		margin-right: 0.4rem;
	}
	.btn_sure {
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
}
</style>
